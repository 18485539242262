.dashboardView {
  text-align: center;
}
.dashboard-body-Img {
  width: 100%;
  height: 340px;
  margin: auto;
  display: flex;
  align-items: center;
  border-radius: 20px;
  justify-content: center;
  background-image: linear-gradient(
    to bottom right,
    #f77cc5,
    #e285e7,
    #e9c7dc,
    #fdeac2,
    #e9c7dc,
    #8bf2f2
  );
}
.dashboard-heading {
  color: black;
  font-size: 45px;
  font-weight: 500;
  text-align: center;
  margin-top: 50px !important;
  line-height: 50px !important;
  margin-bottom: 30px !important;
}
.dashboard-desc {
  font-size: 25px;
  text-align: left;
  font-weight: 300;
  margin-bottom: 50px;
}
.dashboard-img {
  width: 50%;
  object-fit: contain;
}
@media screen and (max-width: 500px) {
  .dashboard-heading {
    font-size: 30px;
    line-height: 30px;
  }
  .dashboard-desc {
    font-size: 20px;
  }
  .dashboard-img {
    width: 90%;
  }
  .dashboard-body-Img {
    height: 250px;
  }
}
