#checked {
  width: 150px;
  height: 150px;
}

#check-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  flex-direction: column;
  /* background-color: aquamarine; */
  text-align: center;
}

#check-container h2 {
  font-size: 30px;
  color: black;
  padding-top: 30px;
  width: 800px;
  font-weight: 400;
}

#home-heading {
  margin: 0;
  font-size: 34px;
  margin-top: 20px;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}

#home-desc {
  font-size: 20px;
  font-weight: 500 !important;
  font-family: Arial, Helvetica, sans-serif;
}

@media only screen and (max-width: 1000px) {
  #check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    flex-direction: column;
    /* background-color: aquamarine; */
    text-align: center;
  }
  #check-container h2 {
    font-size: 28px;
    color: black;
    /* padding-top: 30px; */
    width: 500px;
    font-weight: 400;
  }
  #checked {
    width: 100px;
    height: 100px;
  }
}

@media only screen and (max-width: 600px) {
  #check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    flex-direction: column;
    /* background-color: aquamarine; */
    text-align: center;
  }
  #check-container h2 {
    font-size: 20px;
    color: black;
    /* padding-top: 30px; */
    width: 300px;
    font-weight: 400;
  }
}
