.register-box {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 85px);
}
.register-heading {
  font-size: 45px;
  margin-top: 50px;
  font-weight: bold;
}
.register-sub-heading {
  font-size: 25px;
  margin-top: 40px;
  font-weight: 600;
}
.register-desc {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
}
.privacyText {
  font-size: 15px;
  font-weight: 500;
}
.forgetText {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
  color: rgb(0, 132, 255);
}
.privacyTextBlue {
  display: inline-block;
}
.login-box {
  text-align: center;
  height: calc(100vh - 130);
}
.Hizzy-dashboard-logo {
  width: 100px;
}
.dashboardPowerBox {
  width: 110px;
  margin: 0 auto;
  display: flex;
  margin-top: 20vh;
  margin-bottom: 5px;
  flex-direction: column;
  align-items: flex-start;
}
.dashboardPowerText {
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 3px;
}
