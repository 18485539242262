.homeSphere-Nav {
  display: flex;
  padding: 15px 50px;
  text-align: center;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
  box-shadow: 0 0 10px -3px gray;
  border-bottom: 1px solid gainsboro;
}

.hs-button {
  width: 120;
  height: 40;
  border-radius: 5;
  font-weight: bold;
  text-transform: none;
  background-color: black !important;
}
.homeSphere-logo {
  height: 50px;
}
.Hizzy-logo {
  height: 30px;
}
.powerBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.powerText {
  font-size: 12px;
  margin-bottom: 3px;
}

@media screen and (max-width: 500px) {
  .homeSphere-Nav {
    padding: 15px;
  }
  .Hizzy-logo {
    height: 15px;
  }
  .homeSphere-logo {
    height: 25px;
  }
  .hs-button {
    width: 10;
    height: 3;
    background-color: black;
  }
}
